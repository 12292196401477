import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './index.css';
import Query from './Query';
import History from './History';
import { useAuth } from './AuthContext'
import Settings from './Settings'
import Tree from './Tree';

function Chat() {
  const [selectedDate, setSelectedDate] = useState(null);
  const [queryHistory, setQueryHistory] = useState({});
  //   JSON.parse(localStorage.getItem('queryHistory')) || {}
  // );
  const [isSidebarCollapsed, setSidebarCollapsed] = useState(true);
  const [isSettingsVisible, setSettingsVisible] = useState(false)
  const navigate = useNavigate()
  const { logout } = useAuth()

  const [historyYears, setHistoryYears] = useState({})
  const [historyTree, setHistoryTree] = useState([])

  const updateQueryHistory = (qh) => {
    setQueryHistory(qh);
  };

  useEffect( async () => {
    try {
      const response = await fetch(
        '/api/history_tree',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('jwtToken')}`
          },
        }
      );

      if(!response.ok) {
        throw Error(response.statusText)
      }

      const history_tree = await response.json()
      setHistoryTree(history_tree)
    } catch(error) {
      console.log('history_years fail', error)
    }

  }, []);

  useEffect(async () => {
    try {
      const response = await fetch(
        '/api/history_today',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('jwtToken')}`
          },
        }
      );

      if(!response.ok) {
        throw Error(response.statusText)
      }
      // TODO: same code in Tree.js. Unify code!!
      const result = await response.json()
      const qa_list = {};
      
      for(let i=0; i < result.query_log_list.length; i++){
        const query_log = result.query_log_list[i];
        const date = query_log.timestamp.split("T")[0];
        if( !(date in qa_list) ) {
          qa_list[date] = [];
        }

        const entry = {
          question: query_log["query"],
          answer: query_log["response_body"]["response"],
          documents: query_log["response_body"]["relavant_docs"]
        };
        //qa_list[date].push(entry)
        // push entry into front of list
        qa_list[date] = [entry, ...qa_list[date]];
        
      }
      console.log(qa_list);
      setQueryHistory(qa_list);
    } catch(error) {
      console.log('history_years fail', error)
    }
  }, []);

  const handleDateClick = (date) => {
    setSelectedDate(date);
  };

  const toggleSidebar = () => {
    setSidebarCollapsed(!isSidebarCollapsed);
  };

  // Handle logout function
  const handleLogout = async () => {
    try {
      
      // Optionally, make an API call to the server to log out if needed
      const response = await fetch(
        '/api/logout',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
          },
        }
      );

      // Check for response status
      if (!response.ok) {
        throw new Error('Failed to log out');
      }

      const result = await response.json()
      console.log(result)
      /*
      // Clear JWT token from local storage
      localStorage.removeItem('jwtToken');

      // Redirect to login page
      //window.location.href = '/login';
      navigate('/')
      */
    } catch (error) {
      console.error('Logout error:', error);
    } finally {
      logout();
      

      // // Clear JWT token from local storage
      //  localStorage.removeItem('jwtToken');
      
      // //localStorage.clear()
      // navigate('/')
    }
  };


  return (
    <React.StrictMode>
      <div className="line-height">
        <div className="container">
          {/* Offcanvas Sidebar */}
          <div
            className={`offcanvas offcanvas-start w-25 ${
              isSidebarCollapsed ? '' : 'show'
            }`}
            tabIndex="-1"
            id="offcanvas"
            data-bs-keyboard="false"
            data-bs-backdrop="false"
          >
            <div className="offcanvas-header">
              <button
                type="button"
                className="btn-close text-reset"
                onClick={toggleSidebar}
                aria-label="Close"
              />              
              <a
                href="#"
                className="d-flex justify-content-between align-items-center"
                style={{ textDecoration: 'none' }}
              >
                <button
                  type="button"
                  className="btn bi bi-gear settings"
                  onClick={() => setSettingsVisible(true)}
                >
                  &nbsp;&nbsp;설정
                </button>
              </a>
              <a
                href="#"
                onClick={(event) => { event.preventDefault()}}
                className="d-flex justify-content-between align-items-center"
                style={{ textDecoration: 'none' }}
              >
                <button
                  type="button"
                  className="btn bi bi-box-arrow-left logout"
                  onClick={handleLogout}
                >
                  &nbsp;&nbsp;로그아웃
                </button>
              </a>
            </div>
            {/* 임시 코멘트 처리 */
            <div className="offcanvas-body">
              {/* { <div id="sidebar-nav" className="list-group">
                {Object.keys(queryHistory).map((date, index) => (
                  <a
                    key={index}
                    href="#"
                    onClick={() => handleDateClick(date)}
                    className="list-group-item list-group-item-action"
                  >
                    {date}
                  </a>
                ))}
              </div> }*/}
              <Tree data={historyTree} updateQueryHistory={updateQueryHistory} />
            </div> 
            /* */}

          </div>
        </div>

        {/* Main Content */}

        <div
          className={`main-content col ${
            isSidebarCollapsed ? '' : 'content-expanded'
          }`}
        >
          <div className="ps-md-2 pt-2">
            <button
              className={`btn btn-outline-secondary mb-3 mt-3 menu-button ${
                isSidebarCollapsed ? '' : 'hide'
              }`}
              type="button"
              onClick={toggleSidebar}
              style={{ paddingLeft: '0px', paddingRight: '7px' }}
            >
              <i className="bi bi-list bi-lg py-2 p-1"></i>메뉴
            </button>
          </div>
          {/* Chat Section */}
          <div
            className={`main-content ${
              isSidebarCollapsed ? '' : 'sidebar-expanded'
            }`}
          >
            {/* <History /> */}
            <main className="ps-md-2 pt-2">
              {/* {selectedDate && (
                <div>
                  <h3>{selectedDate}</h3>
                  <ul>
                    {queryHistory[selectedDate].map((item, index) => (
                      <li key={index}>
                        <strong>Question:</strong> {item.question}
                        <br />
                        <strong>Answer:</strong> {item.answer}
                      </li>
                    ))}
                  </ul>
                </div>
              )} */}
              <Query queryHistory={queryHistory} updateQueryHistory={updateQueryHistory}/>
              <p className="footer">PartnersAI</p>
            </main>
          </div>
        </div>
        {/* Render Settings Modal */}
        {isSettingsVisible && <Settings onClose={() => setSettingsVisible(false)} />} 

      </div>
    </React.StrictMode>
  );
}

export default Chat;

